import React from "react"
import { Fragment, useState } from "react"
import CheckFilter from "./CheckFilter"
import { CurrencyField } from "../components/currency-field"
import { XIcon, ArrowPath } from "@heroicons/react/outline"
import FilterIcon from "../icons/filter"
import { Dialog, Popover, Transition } from "@headlessui/react"
import AccordionFilter from "./AccordionFilter"
import { navigate } from "gatsby"

const gemstones = [
  "agate",
  "amazonite",
  "amber",
  "amethyst",
  "apatite",
  "aquamarine",
  "aventurine",
  "azurite",
  "azurite-malachite",
  "bloodstone",
  "black moonstone",
  "black obsidian",
  "blue calcite",
  "blue goldstone",
  "blue lace agate",
  "blue obsidian",
  "blue quartz",
  "blue topaz",
  "celestite",
  "charoite",
  "chrysoprase",
  "citrine",
  "clear quartz",
  "dalmatian jasper",
  "dendritic agate",
  "dragon stone",
  "emerald",
  "fire quartz",
  "flower agate",
  "fluorite",
  "garnet",
  "golden healer",
  "green opal",
  "green tourmaline",
  "hematite",
  "hessonite garnet",
  "howlite",
  "kambaba jasper",
  "kiwi jasper",
  "kunzite",
  "kyanite",
  "labradorite",
  "lapis lazuli",
  "larimar",
  "lemon quartz",
  "lepidolite",
  "malachite",
  "mangano calcite",
  "mookaite",
  "moonstone",
  "moss agate",
  "mystic topaz",
  "orange calcite",
  "orange kyanite",
  "owyhee",
  "peach moonstone",
  "pegmatite",
  "peridot",
  "pink opal",
  "pink tourmaline",
  "pistachio calcite",
  "polychrome jasper",
  "prasiolite",
  "prehnite",
  "pyrite",
  "rainbow moonstone",
  "red calcite",
  "red jasper",
  "rhodonite",
  "rose quartz",
  "ruby",
  "scolecite",
  "selenite",
  "shungite",
  "smokey quartz",
  "sodalite",
  "star amethyst",
  "sunstone",
  "titanium amethyst",
  "tourmaline",
  "tree agate",
  "turkish stick agate",
  "turquoise",
  "unakite",
]

const forms = [
  "specimen",
  "tumble",
  "pendulum",
  "generator",
  "carved",
  "sphere",
  "wand",
  "cluster",
  "pyramid",
  "raw",
  "chips",
]

const feelings = [
  "anxiety",
  "clarity",
  "confidence",
  "fertility",
  "focus",
  "grief",
  "happiness",
  "love",
  "stress",
  "sleep",
  "trust",
]

const collections = ["essential", "kit", "statement", "zodiac"]

const Filters = ({
  currencyCode,
  productTypes,
  tags,
  vendors,
  filters,
  setFilters,
}) => {
  const updateFilter = (key, value) => {
    setFilters((filters) => ({ ...filters, [key]: value }))
  }

  const updateNumeric = (key, value) => {
    if (
      !isNaN(Number(value)) ||
      (value.endsWith(".") && !isNaN(Number(value.substring(0, -1))))
    ) {
      updateFilter(key, value)
    }
  }

  const resetFilter = () => {
    updateFilter("productTypes", [])
    updateFilter("tags", [])
  }
  const [open, setOpen] = useState(false)

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 pt-36 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-xl font-heading text-ec-sea">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ec-earth"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  <AccordionFilter
                    name="Category"
                    items={productTypes}
                    selectedItems={filters.productTypes}
                    setSelectedItems={(value) =>
                      updateFilter("productTypes", value)
                    }
                  />
                  <AccordionFilter
                    name="Gemstone"
                    items={gemstones}
                    selectedItems={filters.tags}
                    setSelectedItems={(value) => updateFilter("tags", value)}
                  />
                  <AccordionFilter
                    name="Form"
                    items={forms}
                    selectedItems={filters.tags}
                    setSelectedItems={(value) => updateFilter("tags", value)}
                  />
                  <AccordionFilter
                    name="Feeling"
                    items={feelings}
                    selectedItems={filters.tags}
                    setSelectedItems={(value) => updateFilter("tags", value)}
                  />
                  <AccordionFilter
                    name="Collection"
                    items={collections}
                    selectedItems={filters.tags}
                    setSelectedItems={(value) => updateFilter("tags", value)}
                  />
                  <div className="border-t border-gray-200 px-4 py-6">
                    {" "}
                    {(filters.productTypes != 0) | (filters.tags != 0) ? (
                      <button
                        className=" py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400"
                        onClick={() => resetFilter()}
                      >
                        <span className="font-medium text-gray-900">
                          Reset Filters
                          <span className=" ml-2 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                            {filters.productTypes.length + filters.tags.length}
                          </span>
                        </span>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
        {/* CATEGORY */}
        {(filters.productTypes != 0) | (filters.tags != 0) ? (
          <button
            className="flex gap-2 items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
            onClick={() => resetFilter()}
          >
            <span>Reset</span>
            <span className=" rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
              {filters.productTypes.length + filters.tags.length}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3"
              />
            </svg>
          </button>
        ) : null}

        <CheckFilter
          name="Category"
          items={productTypes}
          selectedItems={filters.productTypes}
          setSelectedItems={(value) => updateFilter("productTypes", value)}
        />
        <CheckFilter
          name="Gemstone"
          items={gemstones}
          selectedItems={filters.tags}
          setSelectedItems={(value) => updateFilter("tags", value)}
        />
        <CheckFilter
          name="Form"
          items={forms}
          selectedItems={filters.tags}
          setSelectedItems={(value) => updateFilter("tags", value)}
        />
        <CheckFilter
          name="Feeling"
          items={feelings}
          selectedItems={filters.tags}
          setSelectedItems={(value) => updateFilter("tags", value)}
        />
        <CheckFilter
          name="Collection"
          items={collections}
          selectedItems={filters.tags}
          setSelectedItems={(value) => updateFilter("tags", value)}
        />
      </Popover.Group>

      <button
        type="button"
        className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
        onClick={() => setOpen(true)}
      >
        <FilterIcon />
      </button>
    </>
  )
}

export default Filters
