import React from "react"

import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react"

import { ChevronDownIcon } from "@heroicons/react/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const AccordionFilter = ({
  items,
  name,
  selectedItems = [],
  setSelectedItems,
  open = true,
}) => {
  const toggleItem = ({ currentTarget: input }) => {
    if (input.checked) {
      setSelectedItems([...selectedItems, input.value])
    } else {
      const idx = selectedItems.indexOf(input.value)
      if (idx === -1) {
        return
      }
      const newItems = [
        ...selectedItems.slice(0, idx),
        ...selectedItems.slice(idx + 1),
      ]
      setSelectedItems(newItems)
    }
  }

  const clearItems = () => {
    setSelectedItems([])
  }
  return (
    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
              <span className="font-medium text-gray-900">
                {name}{" "}
                {/* {selectedItems.length != 0 ? (
                  <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                    {selectedItems.length}
                  </span>
                ) : null} */}
              </span>

              <span className="ml-6 flex items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-5 w-5 transform"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-6">
              {items.map((item) => (
                <div key={item} className="flex items-center">
                  <input
                    id={`filter-${item}`}
                    name={`${item}[]`}
                    defaultValue={item}
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 rounded text-ec-earth focus:ring-ec-earth"
                    onChange={toggleItem}
                    checked={selectedItems.includes(item)}
                  />
                  <label
                    htmlFor={`filter-${item}`}
                    className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap capitalize"
                  >
                    {item}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default AccordionFilter
