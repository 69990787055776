import React, {Fragment} from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

const CheckFilter = ({
  items,
  name,
  selectedItems = [],
  setSelectedItems,
  open = true,
}) => {
  const toggleItem = ({ currentTarget: input }) => {
    if (input.checked) {
      setSelectedItems([...selectedItems, input.value])
    } else {
      const idx = selectedItems.indexOf(input.value)
      if (idx === -1) {
        return
      }
      const newItems = [
        ...selectedItems.slice(0, idx),
        ...selectedItems.slice(idx + 1),
      ]
      setSelectedItems(newItems)
    }
  }

  const clearItems = () => {
    setSelectedItems([])
  }
  return (
    <Popover
      as="div"
      id={`desktop-menu-category`}
      className="relative z-10 inline-block text-left"
    >
      <div>
        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none">
          <span>
            {" "}
            {name}
          </span>
          {/* {selectedItems.length != 0 ? (
            <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
              {selectedItems.length}
            </span>
          ) : null} */}
          <ChevronDownIcon
            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Popover.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[800px] overflow-y-scroll customnav">
          <form className="space-y-4">
            {items.map((item) => (
              <div key={item} className="flex items-center">
                <input
                  id={`filter-${item}`}
                  name={`${item}[]`}
                  defaultValue={item}
                  type="checkbox"
                  className="h-4 w-4 border-gray-300 rounded text-ec-earth checked:bg-ec-earth"
                  onChange={toggleItem}
                  checked={selectedItems.includes(item)}
                />
                <label
                  htmlFor={`filter-${item}`}
                  className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap capitalize"
                >
                  {item}
                </label>
              </div>
            ))}
          </form>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default CheckFilter
