import React from "react"
import { Link, navigate } from "gatsby"
import Layout from "../content/Layout"
import SEO from "../content/Seo"
import { graphql } from "gatsby"
import slugify from "@sindresorhus/slugify"
import debounce from "debounce"
import { CgChevronRight, CgChevronLeft } from "react-icons/cg"

import CrossIcon from "../icons/cross"
import SortIcon from "../icons/sort"
import SearchIcon from "../icons/search"
import { ProductCard } from "../components/product-card"
import { ProductListing } from "../components/product-listing"
import { useProductSearch } from "../utils/hooks"
import { getValuesFromQuery } from "../utils/search"
import { getCurrencySymbol } from "../utils/format-price"
import { Spinner } from "../components/progress"
import { SearchProvider } from "../context/search-provider"
import Filters from "../content/Filters"
import {
  visuallyHidden,
  main,
  search,
  searchIcon,
  sortSelector,
  results,
  productList as productListStyle,
  productListItem,
  pagination,
  paginationButton,
  progressStyle,
  resultsStyle,
  filterStyle,
  clearSearch,
  searchForm,
  sortIcon,
  filterButton,
  filterTitle,
  modalOpen,
  activeFilters,
  filterWrap,
  emptyState,
} from "./search-page.module.css"

import { Fragment, useState, useEffect } from "react"

import { XIcon } from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { update } from "lodash"

const sortOptions = [
  { name: "Relevance", value: "RELEVANCE" },
  { name: "Price", value: "PRICE" },
  { name: "Name", value: "" },
  { name: "New", value: "CREATED_AT" },
  { name: "Trending", value: "BEST_SELLING" },
]

const DEFAULT_PRODUCTS_PER_PAGE = 120

export async function getServerData({ query, ...rest }) {
  const { getSearchResults } = require("../utils/search")
  const products = await getSearchResults({
    query,
    count: DEFAULT_PRODUCTS_PER_PAGE,
  })

  return {
    props: {
      query,
      products,
    },
  }
}

export const query = graphql`
  query {
    meta: allShopifyProduct {
      productTypes: distinct(field: productType)
      tags: distinct(field: tags)
      vendors: distinct(field: vendor)
    }
  }
`

function SearchPage({
  serverData,
  data: {
    meta: { productTypes, vendors, tags },
  },
  location,
}) {
  // These default values come from the page query string
  const queryParams = getValuesFromQuery(location.search || serverData.query)

  const [filters, setFilters] = React.useState(queryParams)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialFilters = React.useMemo(() => queryParams, [])
  const [sortKey, setSortKey] = React.useState(queryParams.sortKey)
  // We clear the hash when searching, we want to make sure the next page will be fetched due the #more hash.
  const shouldLoadNextPage = React.useRef(false)

  // This modal is only used on mobile
  const [showModal, setShowModal] = React.useState(false)

  const {
    products,
    isFetching,
    filterCount,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useProductSearch(
    filters,
    {
      allProductTypes: productTypes,
      allVendors: vendors,
      allTags: tags,
    },
    sortKey,
    false,
    DEFAULT_PRODUCTS_PER_PAGE,
    serverData.products,
    initialFilters
  )

  // Scroll up when navigating
  React.useEffect(() => {
    if (!showModal) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })
    }
  }, [products, showModal])

  // Stop page from scrolling when modal is visible
  React.useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.overflow = ""
    }
  }, [showModal])

  // React.useEffect(() => {
  //   const currTags = [filters.tags]
  //   const currCats = [filters.productTypes]

  //   if (currCats != 0) {
  //     setFilters((filters) => ({
  //       ...filters,
  //       productTypes: currCats,
  //     }))
  //   } else if (currTags != 0) {
  //     setFilters((filters) => ({
  //       ...filters,
  //       tags: currTags,
  //     }))
  //   }

  // }, [])

  const currencyCode = getCurrencySymbol(
    serverData.products?.[0]?.node?.priceRangeV2?.minVariantPrice?.currencyCode
  )

  function showAll() {
    setFilters((filters) => ({
      ...filters,
      productTypes: [
        "Aromatherapy",
        "Books",
        "Candles",
        "Crystals",
        "Giftware",
        "Jewellery",
        "Tarot & Oracle Cards",
      ],
    }))
    setFilters((filters) => ({ ...filters, tags: [] }))
    setSortKey("TITLE")
  }

  return (
    <Layout>
      <SEO
        title="Product Catalogue"
        slug="/catalogue"
        image="../assets/Header Image Placeholder.jpg"
      />
      <main className="pt-8 md:pt-12">
        <div className="bg-ec-air border-b-2 sm:py-2 border-ec-earth">
          {/* Mobile filter dialog */}

          <div className="max-w-3xl mx-auto px-4 text-center sm:px-6 lg:max-w-screen-2xl lg:px-8 pt-24">
            <section aria-labelledby="filter-heading" className="">
              <h2 id="filter-heading" className="sr-only">
                Product filters
              </h2>

              <div className="flex items-center justify-between">
                <div className="">
                  <label className="w-full flex items-center">
                    <span className="hidden lg:inline-flex group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 w-24">
                      Sort by:
                    </span>
                    <select
                      value={sortKey}
                      // eslint-disable-next-line
                      onChange={(e) => setSortKey(e.target.value)}
                      className="text-sm font-medium text-gray-700 hover:text-gray-900 border-none bg-ec-air focus:outline-0 focus:ring-0 rounded-md cursor-pointer"
                    >
                      <option
                        value="RELEVANCE"
                        className="bg-white hover:bg-ec-air"
                      >
                        Relevance
                      </option>
                      <option
                        value="PRICE"
                        className="bg-white hover:bg-ec-air"
                      >
                        Price
                      </option>
                      <option
                        value="TITLE"
                        className="bg-white hover:bg-ec-air"
                      >
                        Title
                      </option>
                      <option
                        value="CREATED_AT"
                        className="bg-white hover:bg-ec-air"
                      >
                        New items
                      </option>
                      <option
                        value="BEST_SELLING"
                        className="bg-white hover:bg-ec-air"
                      >
                        Trending
                      </option>
                    </select>
                  </label>
                </div>

                <SearchBar
                  defaultTerm={filters.term}
                  setFilters={setFilters}
                  className=""
                />

                <Filters
                  setFilters={setFilters}
                  filters={filters}
                  tags={tags}
                  vendors={vendors}
                  productTypes={productTypes}
                  currencyCode={currencyCode}
                />
              </div>
            </section>
          </div>
        </div>

        <section
          className="max-w-screen-2xl mx-auto"
          aria-busy={isFetching}
          aria-hidden={modalOpen}
        >
          {isFetching ? (
            <p className="flex items-center gap-2 p-6">
              <Spinner aria-valuetext="Searching" /> Searching
              {filters.term ? ` for "${filters.term}"…` : `…`}
            </p>
          ) : (
            <p className="py-6 px-8 font-ec-body text-ec-sea">
              {filters.term && (
                <>
                  Search results for "<span>{filters.term}</span>"
                </>
              )}
            </p>
          )}
          {!isFetching && (
            <div className="md:grid md:grid-cols-2 xl:grid-cols-3 items-center mx-auto justify-center relative content-center justify-items-center p-4">
              {products.map(({ node }, index) => (
                <ProductCard
                  eager={index === 0}
                  product={{
                    title: node.title,
                    priceRangeV2: node.priceRangeV2,
                    slug: `/products/${slugify(node.productType)}/${
                      node.handle
                    }`,
                    // The search API and Gatsby data layer have slightly different images available.
                    images: [],
                    storefrontImages: node.images,
                    vendor: node.vendor,
                  }}
                  key={index}
                />
              ))}
            </div>
          )}
          {!isFetching && products.length === 0 && (
            <div className={emptyState}>
              No results found, Select a category or try another search
            </div>
          )}
          {hasPreviousPage || hasNextPage ? (
            <Pagination
              previousPage={fetchPreviousPage}
              hasPreviousPage={hasPreviousPage}
              nextPage={fetchNextPage}
              hasNextPage={hasNextPage}
            />
          ) : (
            <div className="flex justify-center items-center">
              <button
                onClick={() => {
                  showAll()
                }}
                className="flex items-center justify-center px-4 py-3 border-2 border-ec-earth rounded-full text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth transition ease-in-out text-xl font-heading  sm:px-8 mb-12 "
              >
                Shop all products
              </button>
            </div>
          )}
        </section>
      </main>
    </Layout>
  )
}

function SearchBar({ defaultTerm, setFilters }) {
  const [term, setTerm] = React.useState(defaultTerm)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilters = React.useCallback(
    debounce((value) => {
      setFilters((filters) => ({ ...filters, term: value }))
    }, 200),
    [setFilters]
  )

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="items-center flex gap-4 rounded-md w-full sm:mx-6 md:mr-24 bg-ec-air"
    >
      <SearchIcon aria-hidden className="hidden sm:inline" />
      <input
        type="text"
        value={term}
        onChange={(e) => {
          setTerm(e.target.value)
          debouncedSetFilters(e.target.value)
        }}
        placeholder="Search..."
        className="bg-transparent p-4 w-full focus-visible:outline-none border-none focus:ring-0 focus:outline-0 focus:border-0"
      />
      {term ? (
        <button
          className="items-center p-4"
          type="reset"
          onClick={() => {
            setTerm("")
            setFilters((filters) => ({ ...filters, term: "" }))
          }}
          aria-label="Clear search query"
        >
          <CrossIcon />
        </button>
      ) : undefined}
    </form>
  )
}

function Pagination({ previousPage, hasPreviousPage, nextPage, hasNextPage }) {
  return (
    <nav className="flex w-full justify-center gap-12 items-center px-4 pb-12">
      <button
        className={
          !hasPreviousPage
            ? "flex items-center justify-center  w-36 px-4 py-2 border-2 rounded-full hover:bg-transparent transition ease-in-out text-lg font-heading  sm:px-8 mb-12 border-gray-200 text-gray-200"
            : "flex items-center justify-center  w-36 px-4 py-2 border-2 border-ec-earth rounded-full text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth transition ease-in-out text-lg font-heading  sm:px-8 mb-12"
        }
        disabled={!hasPreviousPage}
        onClick={previousPage}
        aria-label="Previous page"
      >
        <CgChevronLeft /> <p className="">Previous</p>
      </button>
      <button
        className={
          !hasNextPage
            ? "flex items-center justify-center  w-36 px-4 py-2 border-2 rounded-full hover:bg-transparent transition ease-in-out text-lg font-heading  sm:px-8 mb-12 border-gray-200 text-gray-200"
            : "flex items-center justify-center  w-36 px-4 py-2 border-2 border-ec-earth rounded-full text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth transition ease-in-out text-lg font-heading  sm:px-8 mb-12"
        }
        disabled={!hasNextPage}
        onClick={nextPage}
        aria-label="Next page"
      >
        <p className="">Next</p>
        <CgChevronRight />
      </button>
    </nav>
  )
}

const Catalogue = (props) => {
  return (
    <SearchProvider>
      <SearchPage {...props} />
    </SearchProvider>
  )
}
export default Catalogue
